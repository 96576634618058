import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Client, ClientBrandingElementNames, ClientUpdateDto } from '../../models/Client';
import ClientService from '../../services/ClientService';
import FileService from '../../services/FileService';
import UploadedImage from '../shared/file-upload/UploadedImage';
import { FileInfo } from '../shared/file-upload/FileInfo';
import UploadArea from '../shared/file-upload/UploadArea';
import Button, { ButtonType } from '../shared/form-control/Button';
import { Input } from '../shared/form-control/Input';
import PageLoader from '../shared/page-loader/PageLoader';
import ProgressBar from '../shared/ProgressBar';
import { Heading, HeadingSize } from '../shared/text/Heading';
import { Roles } from '../../models/Role';
import { ToastType, useToasts } from '../../contexts/ToastContext';
import { useTranslation } from 'react-i18next';
import AddressLocation from '../../models/AddressLocation';
import AddressInput from '../shared/form-control/AddressInput';
import usePermissions from '../../hooks/permissions/usePermissions';
import LanguageSelector from '../shared/LanguageSelector';
import ColorPicker from '../shared/ColorPicker';
import StandardModal from '../shared/modal/variants/StandardModal';
import { ModalContext } from '../../contexts/ModalContext';
import { useCurrentClient } from '../../global-state/Clients';
import { useShallow } from 'zustand/react/shallow';

const BRANDING_DEFAULT_COLOR = '#000000';

const OrgDetails: FC<{ client?: Client }> = ({ client }) => {
  const [orgDetails, setOrgDetails] = useState<ClientUpdateDto | null>(null);
  const [uploadProgress, setUploadProgress] = useState(-1);
  const hasPermission = usePermissions();
  const [isDirty, setIsDirty] = useState(false);
  const [resetBrandingColorsModalOpen, setResetBrandingColorsModalOpen] = useState(false);

  const [currentClient, setClient] = useCurrentClient(useShallow((x) => [x.value, x.setValue]));
  const toasts = useToasts();
  const { t } = useTranslation('organisation');

  useEffect(() => {
    // if client is set dont return out
    if (client) {
      setOrgDetails(client);
      return;
    }
    if (!currentClient) {
      return;
    }
    ClientService.getClient(currentClient.id).then((res) => {
      setOrgDetails({
        ...res,
      });
    });
  }, [client, currentClient, setOrgDetails]);

  const setCompanyField = (key: string, value: string) => {
    if (!orgDetails) {
      return;
    }
    setIsDirty(true);
    setOrgDetails({
      ...orgDetails,
      [key]: value,
    });
  };

  const setBrandingElement = useCallback((key: ClientBrandingElementNames, hexColour: string) => {
    setIsDirty(true);
    setOrgDetails(
      (prev) =>
        prev && {
          ...prev,
          clientBranding: {
            ...(prev.clientBranding ?? {}),
            brandingElements: {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ...(prev.clientBranding?.brandingElements ?? ({} as any)),
              [key]: {
                hexColour,
              },
            },
          },
        },
    );
  }, []);

  const setLocation = (value: AddressLocation | null) => {
    if (!orgDetails) {
      return;
    }
    setIsDirty(true);
    setOrgDetails({
      ...orgDetails,
      location: value || undefined,
    });
  };

  const onImageSelected = (file: FileInfo) => {
    uploadFile(file.file);
  };

  const uploadFile = (file: File) => {
    FileService.uploadFile(file, (perc) => setUploadProgress(perc)).then((res) => {
      setUploadProgress(-1);
      setCompanyField('logoId', res.data[0].id);
    });
  };

  const saveDetails = useCallback(() => {
    const clientId = currentClient?.id || client?.id;
    if (clientId && orgDetails) {
      ClientService.updateClient(clientId, orgDetails).then((res) => {
        toasts.addToast({ title: t('details.toasts.saved'), type: ToastType.SUCCESS, expiresInMs: 5000 });
        if (currentClient) {
          setClient({ ...currentClient, ...res });
        }
        setIsDirty(false);
      });
    }
  }, [client?.id, currentClient, orgDetails, setClient, t, toasts]);

  const brandingElements = useMemo<ClientBrandingElementNames[]>(() => {
    return ['h1', 'h2', 'h3', 'h4', 'doc-section-header', 'normal-text'] as const;
  }, []);

  const memoizedSetBrandingElements = useMemo(() => {
    const result = brandingElements
      .map((key) => {
        return [key, (color: string) => setBrandingElement(key, color)] as const;
      })
      .reduce(
        (acc, [key, fn]) => {
          acc[key] = fn;
          return acc;
        },
        {} as Record<ClientBrandingElementNames, (color: string) => void>,
      );

    return result;
  }, [brandingElements, setBrandingElement]);

  const isAllBrandingColoursDefault = useMemo(() => {
    return brandingElements.every(
      (color) => (orgDetails?.clientBranding?.brandingElements?.[color]?.hexColour ?? BRANDING_DEFAULT_COLOR) === BRANDING_DEFAULT_COLOR,
    );
  }, [brandingElements, orgDetails?.clientBranding?.brandingElements]);

  const resetBrandingColors = useCallback(() => {
    for (const key of brandingElements) {
      memoizedSetBrandingElements[key](BRANDING_DEFAULT_COLOR);
    }
  }, [brandingElements, memoizedSetBrandingElements]);

  return (
    <PageLoader loading={orgDetails === null}>
      {orgDetails && (
        <>
          <div className="w-full">
            <div className="my-6 flex justify-between">
              <Heading size={HeadingSize.H3}>{t('tabs.details')}</Heading>
              <div className="mt-4 flex justify-end">
                <Button data-cy="save" type={ButtonType.PRIMARY} onClick={saveDetails} disabled={!hasPermission(Roles.TeamLead) || !isDirty}>
                  {t('details.buttons.save')}
                </Button>
              </div>
            </div>

            <div className="flex gap-8">
              <div className="flex w-2/3 flex-col gap-1">
                <Input
                  data-cy="input-name"
                  placeholder={t('details.inputs.name')}
                  label={t('details.inputs.name')}
                  value={orgDetails.name || ''}
                  onChange={(e) => setCompanyField('name', e.target.value)}
                  className="bg-white"
                />
                <Input
                  data-cy="input-size"
                  placeholder={t('details.inputs.size')}
                  label={t('details.inputs.size')}
                  value={orgDetails.size || ''}
                  onChange={(e) => setCompanyField('size', e.target.value)}
                  className="bg-white"
                />

                <AddressInput
                  initialValue={orgDetails.location?.address}
                  placeholder={t('details.inputs.address')}
                  onClear={() => setLocation(null)}
                  onAddressChanged={setLocation}
                  label={t('details.inputs.address')}
                />

                <Input
                  data-cy="input-contact"
                  placeholder={t('details.inputs.contact')}
                  label={t('details.inputs.contact')}
                  value={orgDetails.contactNumber || ''}
                  onChange={(e) => setCompanyField('contactNumber', e.target.value)}
                  className="bg-white"
                />

                <LanguageSelector
                  label={t('details.inputs.language')}
                  setCurrentUserLanguage={false}
                  onLanguageChange={(language) => setCompanyField('language', language)}
                  value={orgDetails.language}
                />
              </div>
              <div className="h-full w-1/3 bg-white py-4 text-center">
                <Heading size={HeadingSize.H4}>{t('details.logo.heading')}</Heading>
                <div className="text-color-3 visible mb-8 mt-4"> {t('details.logo.sub-heading')}</div>
                <div className="mx-auto h-[300px] w-[300px] overflow-hidden py-4">
                  <UploadArea onUpload={(files) => onImageSelected(files[0])} acceptMimes="image/jpeg,image/png,image/gif" className="h-full">
                    {(_, uploadContent) => (
                      <>
                        {orgDetails.logoId && (
                          <div className="relative p-4 opacity-40">
                            <UploadedImage uniqueId={currentClient?.id ?? ''} fileId={orgDetails.logoId} className="scale-150 transform" />
                          </div>
                        )}

                        <div className="z-popover absolute bottom-0 left-0 right-0 top-0 flex flex-col items-center justify-center p-4 text-center">
                          {uploadContent}
                        </div>
                      </>
                    )}
                  </UploadArea>
                  {uploadProgress >= 0 && (
                    <div className="mx-auto my-4 w-1/3">
                      <ProgressBar progress={uploadProgress} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="mb-4">
            <div className="border-b pb-6 pr-6">
              <Heading size={HeadingSize.H4}>{t('branding.title')}</Heading>
              <div className="text-gray-1">{t('branding.subtitle')}</div>
            </div>
            <div className="py-6 pr-6">
              <div>
                <div className="font-medium">{t('branding.color.title')}</div>
                <div className="text-gray-1">{t('branding.color.subtitle')}</div>
              </div>
              <div className="mt-4 flex flex-wrap gap-4 xl:flex-nowrap">
                {brandingElements.map((color) => (
                  <div key={color} className="flex flex-col items-center">
                    <label className="text-gray-1 ml-0 font-medium" id={color} htmlFor={`${color}-input`}>
                      <span className="ml-12 pb-2">{t(`branding.color.choices.${color}`)}</span>
                      <ColorPicker
                        value={orgDetails.clientBranding?.brandingElements?.[color]?.hexColour ?? BRANDING_DEFAULT_COLOR}
                        onChange={memoizedSetBrandingElements[color]}
                        aria-labelledby={color}
                        id={`${color}-input`}
                      />
                    </label>
                  </div>
                ))}
              </div>

              <div className="mt-2 flex justify-end empty:hidden">
                {!isAllBrandingColoursDefault && (
                  <Button data-cy="reset-branding-colors" type={ButtonType.TERTIARY} onClick={() => setResetBrandingColorsModalOpen(true)}>
                    {t('branding.color.reset.button')}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      <ModalContext.Provider
        value={{ open: resetBrandingColorsModalOpen, onClose: () => setResetBrandingColorsModalOpen(false), modalWidth: 'w-1/4' }}
      >
        <StandardModal
          title={t('branding.color.reset.title')}
          confirmButtonTitle={t('branding.color.reset.confirm')}
          onCancelClick={() => setResetBrandingColorsModalOpen(false)}
          onConfirmClick={() => {
            setResetBrandingColorsModalOpen(false);
            resetBrandingColors();
          }}
        >
          {t('branding.color.reset.description')}
        </StandardModal>
      </ModalContext.Provider>
    </PageLoader>
  );
};

export default OrgDetails;
